// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import Product1 from './pages/Product1';
import Blog1 from './pages/Blog1';
import Blog2 from './pages/Blog2';
import Blog3 from './pages/Blog3';
import Blog4 from './pages/Blog4';
import UseCasesPage from './pages/UseCasesPage';
import TermsAndConditions from './pages/TermsAndConditions';
import ThankYou from './pages/ThankYou';
import Signup from './pages/Signup';
import PrivacyPolicy from './pages/PrivacyPolicy';

import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import TagManager from 'react-gtm-module';

function App() {
  useEffect(() => {
    // Initialize AOS
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    };

    window.addEventListener('load', aos_init);

    return () => {
      window.removeEventListener('load', aos_init);
    };
  }, []);

  useDocTitle("CrawlSight");

  useEffect(() => {
    // Initialize Google Tag Manager
    const tagManagerArgs = {
      gtmId: 'G-QD0440WZBE', // Replace with your GTM ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/get-demo" element={<DemoProduct />} />
          <Route path="/product1" element={<Product1 />} />
          <Route path="/article-financial_news_analysis" element={<Blog1 />} />
          <Route path="/article-competition_monitoring" element={<Blog2 />} />
          <Route path="/article-summary_of_an_entity_based_on_news_events" element={<Blog3 />} />
          <Route path="/article-food_review_summarization_model" element={<Blog4 />} />
          <Route path="/use-cases" element={<UseCasesPage />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
