// src/pages/Signup.js
import React from 'react';

function Signup() {
  return (
    <div>
      <h1>Signup Page</h1>
      {/* Add your signup form or content here */}
    </div>
  );
}

export default Signup;
