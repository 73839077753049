import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import Notiflix from 'notiflix';
import '../Contact.css'; // Ensure this CSS file exists
import Terms from './Terms';
import Policies from './Policies'


const countries = [
    { code: '+93', name: 'Afghanistan' },
    { code: '+355', name: 'Albania' },
    { code: '+213', name: 'Algeria' },
    { code: '+1', name: 'Andorra' },
    { code: '+244', name: 'Angola' },
    { code: '+1', name: 'Antigua and Barbuda' },
    { code: '+54', name: 'Argentina' },
    { code: '+374', name: 'Armenia' },
    { code: '+297', name: 'Aruba' },
    { code: '+61', name: 'Australia' },
    { code: '+43', name: 'Austria' },
    { code: '+994', name: 'Azerbaijan' },
    { code: '+1', name: 'Bahamas' },
    { code: '+973', name: 'Bahrain' },
    { code: '+880', name: 'Bangladesh' },
    { code: '+1', name: 'Barbados' },
    { code: '+375', name: 'Belarus' },
    { code: '+32', name: 'Belgium' },
    { code: '+501', name: 'Belize' },
    { code: '+229', name: 'Benin' },
    { code: '+1', name: 'Bermuda' },
    { code: '+975', name: 'Bhutan' },
    { code: '+591', name: 'Bolivia' },
    { code: '+387', name: 'Bosnia and Herzegovina' },
    { code: '+267', name: 'Botswana' },
    { code: '+55', name: 'Brazil' },
    { code: '+246', name: 'British Indian Ocean Territory' },
    { code: '+1', name: 'British Virgin Islands' },
    { code: '+673', name: 'Brunei' },
    { code: '+359', name: 'Bulgaria' },
    { code: '+226', name: 'Burkina Faso' },
    { code: '+257', name: 'Burundi' },
    { code: '+855', name: 'Cambodia' },
    { code: '+1', name: 'Canada' },
    { code: '+238', name: 'Cape Verde' },
    { code: '+1', name: 'Cayman Islands' },
    { code: '+236', name: 'Central African Republic' },
    { code: '+235', name: 'Chad' },
    { code: '+56', name: 'Chile' },
    { code: '+86', name: 'China' },
    { code: '+61', name: 'Christmas Island' },
    { code: '+672', name: 'Cocos (Keeling) Islands' },
    { code: '+57', name: 'Colombia' },
    { code: '+269', name: 'Comoros' },
    { code: '+242', name: 'Congo' },
    { code: '+243', name: 'Congo, Democratic Republic of the' },
    { code: '+682', name: 'Cook Islands' },
    { code: '+506', name: 'Costa Rica' },
    { code: '+225', name: 'Côte d\'Ivoire' },
    { code: '+385', name: 'Croatia' },
    { code: '+53', name: 'Cuba' },
    { code: '+599', name: 'Curaçao' },
    { code: '+357', name: 'Cyprus' },
    { code: '+420', name: 'Czech Republic' },
    { code: '+45', name: 'Denmark' },
    { code: '+253', name: 'Djibouti' },
    { code: '+1', name: 'Dominica' },
    { code: '+1809', name: 'Dominican Republic' },
    { code: '+593', name: 'Ecuador' },
    { code: '+20', name: 'Egypt' },
    { code: '+503', name: 'El Salvador' },
    { code: '+240', name: 'Equatorial Guinea' },
    { code: '+291', name: 'Eritrea' },
    { code: '+372', name: 'Estonia' },
    { code: '+251', name: 'Ethiopia' },
    { code: '+500', name: 'Falkland Islands' },
    { code: '+298', name: 'Faroe Islands' },
    { code: '+679', name: 'Fiji' },
    { code: '+358', name: 'Finland' },
    { code: '+33', name: 'France' },
    { code: '+689', name: 'French Polynesia' },
    { code: '+241', name: 'Gabon' },
    { code: '+220', name: 'Gambia' },
    { code: '+995', name: 'Georgia' },
    { code: '+49', name: 'Germany' },
    { code: '+233', name: 'Ghana' },
    { code: '+350', name: 'Gibraltar' },
    { code: '+30', name: 'Greece' },
    { code: '+299', name: 'Greenland' },
    { code: '+1', name: 'Grenada' },
    { code: '+590', name: 'Guadeloupe' },
    { code: '+1', name: 'Guam' },
    { code: '+502', name: 'Guatemala' },
    { code: '+224', name: 'Guinea' },
    { code: '+245', name: 'Guinea-Bissau' },
    { code: '+595', name: 'Paraguay' },
    { code: '+509', name: 'Haiti' },
    { code: '+504', name: 'Honduras' },
    { code: '+36', name: 'Hungary' },
    { code: '+354', name: 'Iceland' },
    { code: '+91', name: 'India' },
    { code: '+62', name: 'Indonesia' },
    { code: '+98', name: 'Iran' },
    { code: '+964', name: 'Iraq' },
    { code: '+353', name: 'Ireland' },
    { code: '+972', name: 'Israel' },
    { code: '+39', name: 'Italy' },
    { code: '+1', name: 'Jamaica' },
    { code: '+81', name: 'Japan' },
    { code: '+962', name: 'Jordan' },
    { code: '+7', name: 'Kazakhstan' },
    { code: '+254', name: 'Kenya' },
    { code: '+686', name: 'Kiribati' },
    { code: '+850', name: 'North Korea' },
    { code: '+82', name: 'South Korea' },
    { code: '+965', name: 'Kuwait' },
    { code: '+996', name: 'Kyrgyzstan' },
    { code: '+856', name: 'Laos' },
    { code: '+371', name: 'Latvia' },
    { code: '+961', name: 'Lebanon' },
    { code: '+266', name: 'Lesotho' },
    { code: '+231', name: 'Liberia' },
    { code: '+218', name: 'Libya' },
    { code: '+423', name: 'Liechtenstein' },
    { code: '+370', name: 'Lithuania' },
    { code: '+352', name: 'Luxembourg' },
    { code: '+261', name: 'Madagascar' },
    { code: '+265', name: 'Malawi' },
    { code: '+60', name: 'Malaysia' },
    { code: '+960', name: 'Maldives' },
    { code: '+223', name: 'Mali' },
    { code: '+356', name: 'Malta' },
    { code: '+692', name: 'Marshall Islands' },
    { code: '+596', name: 'Martinique' },
    { code: '+222', name: 'Mauritania' },
    { code: '+230', name: 'Mauritius' },
    { code: '+52', name: 'Mexico' },
    { code: '+691', name: 'Micronesia' },
    { code: '+373', name: 'Moldova' },
    { code: '+377', name: 'Monaco' },
    { code: '+976', name: 'Mongolia' },
    { code: '+382', name: 'Montenegro' },
    { code: '+1', name: 'Montserrat' },
    { code: '+212', name: 'Morocco' },
    { code: '+258', name: 'Mozambique' },
    { code: '+95', name: 'Myanmar' },
    { code: '+264', name: 'Namibia' },
    { code: '+674', name: 'Nauru' },
    { code: '+977', name: 'Nepal' },
    { code: '+31', name: 'Netherlands' },
    { code: '+599', name: 'Netherlands Antilles' },
    { code: '+687', name: 'New Caledonia' },
    { code: '+64', name: 'New Zealand' },
    { code: '+505', name: 'Nicaragua' },
    { code: '+227', name: 'Niger' },
    { code: '+234', name: 'Nigeria' },
    { code: '+683', name: 'Niue' },
    { code: '+672', name: 'Norfolk Island' },
    { code: '+1', name: 'Northern Mariana Islands' },
    { code: '+47', name: 'Norway' },
    { code: '+968', name: 'Oman' },
    { code: '+92', name: 'Pakistan' },
    { code: '+680', name: 'Palau' },
    { code: '+970', name: 'Palestine' },
    { code: '+507', name: 'Panama' },
    { code: '+675', name: 'Papua New Guinea' },
    { code: '+595', name: 'Paraguay' },
    { code: '+51', name: 'Peru' },
    { code: '+63', name: 'Philippines' },
    { code: '+48', name: 'Poland' },
    { code: '+351', name: 'Portugal' },
    { code: '+1787', name: 'Puerto Rico' },
    { code: '+974', name: 'Qatar' },
    { code: '+40', name: 'Romania' },
    { code: '+7', name: 'Russia' },
    { code: '+250', name: 'Rwanda' },
    { code: '+590', name: 'Saint Barthelemy' },
    { code: '+290', name: 'Saint Helena' },
    { code: '+1', name: 'Saint Kitts and Nevis' },
    { code: '+1', name: 'Saint Lucia' },
    { code: '+1', name: 'Saint Vincent and the Grenadines' },
    { code: '+685', name: 'Samoa' },
    { code: '+378', name: 'San Marino' },
    { code: '+239', name: 'Sao Tome and Principe' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+221', name: 'Senegal' },
    { code: '+381', name: 'Serbia' },
    { code: '+248', name: 'Seychelles' },
    { code: '+232', name: 'Sierra Leone' },
    { code: '+65', name: 'Singapore' },
    { code: '+721', name: 'Sint Maarten' },
    { code: '+963', name: 'Syria' },
    { code: '+268', name: 'Swaziland' },
    { code: '+46', name: 'Sweden' },
    { code: '+41', name: 'Switzerland' },
    { code: '+964', name: 'Iraq' },
    { code: '+886', name: 'Taiwan' },
    { code: '+992', name: 'Tajikistan' },
    { code: '+255', name: 'Tanzania' },
    { code: '+66', name: 'Thailand' },
    { code: '+670', name: 'Timor-Leste' },
    { code: '+228', name: 'Togo' },
    { code: '+690', name: 'Tokelau' },
    { code: '+676', name: 'Tonga' },
    { code: '+1', name: 'Trinidad and Tobago' },
    { code: '+216', name: 'Tunisia' },
    { code: '+90', name: 'Turkey' },
    { code: '+993', name: 'Turkmenistan' },
    { code: '+1', name: 'Turks and Caicos Islands' },
    { code: '+688', name: 'Tuvalu' },
    { code: '+256', name: 'Uganda' },
    { code: '+380', name: 'Ukraine' },
    { code: '+971', name: 'United Arab Emirates' },
    { code: '+44', name: 'United Kingdom' },
    { code: '+1', name: 'United States' },
    { code: '+598', name: 'Uruguay' },
    { code: '+998', name: 'Uzbekistan' },
    { code: '+678', name: 'Vanuatu' },
    { code: '+379', name: 'Vatican City' },
    { code: '+58', name: 'Venezuela' },
    { code: '+84', name: 'Vietnam' },
    { code: '+681', name: 'Wallis and Futuna' },
    { code: '+967', name: 'Yemen' },
    { code: '+260', name: 'Zambia' },
    { code: '+263', name: 'Zimbabwe' },
];

const Contact = () => {
    useDocTitle('Contact Us - CrawlSight');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('+1');
    const [countryName, setCountryName] = useState('United States');
    const [companyName, setCompanyName] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [selectedProducts, setSelectedProducts] = useState('');
    const [message, setMessage] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [keepUpdated, setKeepUpdated] = useState(false);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [filteredCountries, setFilteredCountries] = useState(countries);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const [pageHeight, setPageHeight] = useState(window.innerHeight);

    const navigate = useNavigate();
    const inputRef = useRef(null);
    const containerRef = useRef(null);

    // Handle window resize
    useEffect(() => {
        const handleResize = () => {
            setPageWidth(window.innerWidth);
            setPageHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Handle clicks outside the input field to close suggestions
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);


    const clearErrors = () => {
        setErrors([]);
        setError(null);
    };

    const clearInput = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setCountryCode('+1');
        setCountryName('United States');
        setCompanyName('');
        setSelectedService('');
        setSelectedProducts('');
        setMessage('');
        setAcceptTerms(false);
        setKeepUpdated(false);
    };

    const validateForm = () => {
        const newErrors = [];
        if (!firstName) newErrors.push('First Name is required.');
        if (!email || !/\S+@\S+\.\S+/.test(email)) newErrors.push('A valid Email is required.');
        if (!selectedService) newErrors.push('Please select a Service.');
        if (!selectedProducts) newErrors.push('Please select a Product.');
        if (!message) newErrors.push('Please describe your use case.');
        if (!acceptTerms) newErrors.push('You must agree to the Terms of Service and Privacy Policy.');
        return newErrors;
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        clearErrors();
        setError(null);
        setLoading(true);

        const validationErrors = validateForm();
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            setLoading(false);
            return;
        }

        const formData = JSON.stringify({
            email,
            business_name: companyName,
            first_name: firstName,
            last_name: lastName,
            isd_code: countryCode,
            phone: phoneNumber,
            services: selectedService,
            products: selectedProducts,
            use_case: message
        });

        try {
            const response = await fetch(process.env.REACT_APP_CSAI_CONTACT_API, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            clearInput();
            navigate('/thank-you');
        } catch (error) {
            if (error.message.includes('500')) {
                setError('Internal Server Error. Please try again later.');
                Notiflix.Report.failure('An error occurred', 'Internal Server Error', 'Okay');
            } else if (error.message.includes('NetworkError')) {
                setError('No response received from the server. Check your network connection.');
                Notiflix.Report.failure('Network Error', 'No response received from the server. Check your network connection.', 'Okay');
            } else {
                setError('An error occurred. Please try again later.');
                Notiflix.Report.failure('Error', 'An error occurred. Please try again later.', 'Okay');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        setFilteredCountries(query
            ? countries.filter(country => country.name.toLowerCase().includes(query))
            : countries
        );
        setShowSuggestions(!!query);
    };

    const handleCountrySelect = (code, name) => {
        setCountryCode(code);
        setCountryName(name);
        setSearchQuery(`${name} (${code})`);
        setShowSuggestions(false);
        setHighlightedIndex(-1);
    };

    const handleKeyDown = (e) => {
        if (showSuggestions) {
            const items = document.querySelectorAll('.suggestion-item');
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setHighlightedIndex((prevIndex) => {
                    const newIndex = (prevIndex + 1) % (items.length || 1);
                    items[newIndex]?.scrollIntoView({ block: 'nearest' });
                    return newIndex;
                });
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setHighlightedIndex((prevIndex) => {
                    const newIndex = (prevIndex - 1 + (items.length || 1)) % (items.length || 1);
                    items[newIndex]?.scrollIntoView({ block: 'nearest' });
                    return newIndex;
                });
            } else if (e.key === 'Enter') {
                e.preventDefault();
                if (highlightedIndex >= 0 && highlightedIndex < (items.length || 0)) {
                    const selectedCountry = filteredCountries[highlightedIndex];
                    handleCountrySelect(selectedCountry.code, selectedCountry.name);
                }
            }
        }
    };

    const Row = ({ index, style }) => {
        const country = filteredCountries[index];
        return (
            <div
                className={`suggestion-item p-2 cursor-pointer ${highlightedIndex === index ? 'highlighted' : ''}`}
                style={style}
                onClick={() => handleCountrySelect(country.code, country.name)}
                onMouseEnter={() => setHighlightedIndex(index)}
            >
                {country.code} ({country.name})
            </div>
        );
    };

    return (
        <>
            <NavBar />
            <main className="p-4" style={{ marginTop: '100px' }}>
                <div className="mx-auto" style={{ maxWidth: pageWidth * 0.5, maxHeight: pageHeight * 1.50 }}>
                    <div className="bg-white p-6 rounded-lg shadow-md" ref={containerRef}>
                        <h1 className="text-2xl font-semibold mb-4">Contact Us</h1>
                        <form onSubmit={sendEmail}>
                            <div className="space-y-4">
                                <div className="flex space-x-2">
                                    <div className="flex-1">
                                        <input
                                            name="first_name"
                                            className="w-full bg-gray-100 text-gray-900 p-2 rounded-lg focus:outline-none"
                                            type="text"
                                            placeholder="First Name*"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            onKeyUp={clearErrors}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <input
                                            name="last_name"
                                            className="w-full bg-gray-100 text-gray-900 p-2 rounded-lg focus:outline-none"
                                            type="text"
                                            placeholder="Last Name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            onKeyUp={clearErrors}
                                        />
                                    </div>
                                </div>

                                <div className="flex space-x-2">
                                    <div className="flex-1 relative" ref={inputRef}>
                                        <input
                                            name="country"
                                            className="w-full bg-gray-100 text-gray-900 p-2 rounded-lg focus:outline-none"
                                            type="text"
                                            placeholder="Country*"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            onKeyDown={handleKeyDown}
                                            onFocus={() => setShowSuggestions(true)}
                                        />
                                        {showSuggestions && (
                                            <List
                                                height={200}
                                                itemCount={filteredCountries.length}
                                                itemSize={35}
                                                width={inputRef.current?.offsetWidth || 0}
                                                className="suggestions-list absolute border bg-white border-gray-300 rounded-md mt-1"
                                            >
                                                {Row}
                                            </List>
                                        )}
                                    </div>
                                    <div className="flex-1">
                                        <input
                                            name="phone_number"
                                            className="w-full bg-gray-100 text-gray-900 p-2 rounded-lg focus:outline-none"
                                            type="text"
                                            placeholder="Phone Number*"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            onKeyUp={clearErrors}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col space-y-2">
                                    <input
                                        name="email"
                                        className="w-full bg-gray-100 text-gray-900 p-2 rounded-lg focus:outline-none"
                                        type="email"
                                        placeholder="Email*"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors.includes('A valid Email is required.') && <p className="text-red-500 text-sm mt-1">A valid Email is required.</p>}
                                </div>

                                <div className="flex flex-col space-y-2">
                                    <input
                                        name="company_name"
                                        className="w-full bg-gray-100 text-gray-900 p-2 rounded-lg focus:outline-none"
                                        type="text"
                                        placeholder="Company Name"
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                    />
                                </div>

                                <div className="flex flex-col space-y-2">
                                    <select
                                        name="service"
                                        className="w-full bg-gray-100 text-gray-900 p-2 rounded-lg focus:outline-none"
                                        value={selectedService}
                                        onChange={(e) => setSelectedService(e.target.value)}
                                    >
                                        <option value="">Select Service*</option>
                                        <option value="Data Collector">Web Data Service</option>
                                        <option value="Data Analytics">Data Catalogs</option>
                                        <option value="Chat Bot">Data Engineering</option>
                                        <option value="LLM Models">Data Analytics</option>
                                        <option value="LLM Models">Machine Learning & AI</option>
                                        <option value="Other">Others</option>
                                    </select>
                                    {errors.includes('Please select a Service.') && <p className="text-red-500 text-sm mt-1">Please select a Service.</p>}
                                </div>

                                <div className="flex flex-col space-y-2">
                                    <select
                                        name="products"
                                        className="w-full bg-gray-100 text-gray-900 p-2 rounded-lg focus:outline-none"
                                        value={selectedProducts}
                                        onChange={(e) => setSelectedProducts(e.target.value)}
                                    >
                                        <option value="">Select Product*</option>
                                        <option value="Web Data API">Web Data API</option>
                                        <option value="Digital World Presence">Digital World Presence</option>
                                        <option value="Digital Feedback Analytics">Digital Feedback Analytics</option>
                                        <option value="Financial News Analytics">Financial News Analytics</option>
                                    </select>
                                    {errors.includes('Please select a Product.') && <p className="text-red-500 text-sm mt-1">Please select a Product.</p>}
                                </div>

                                <div className="flex flex-col space-y-2">
                                    <textarea
                                        name="message"
                                        className="w-full bg-gray-100 text-gray-900 p-2 rounded-lg focus:outline-none"
                                        placeholder="Describe your use case*"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        rows="4"
                                    />
                                    {errors.includes('Please describe your use case.') && <p className="text-red-500 text-sm mt-1">Please describe your use case.</p>}
                                </div>

                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="accept_terms"
                                        checked={acceptTerms}
                                        onChange={() => setAcceptTerms(!acceptTerms)}
                                    />
                                    <label htmlFor="accept_terms" className="text-sm">
                                      <span className="inline-flex items-center">  I agree to the &nbsp;<p onClick={openModal} className="text-blue-500 cursor-pointer"> Terms of Service</p></span>
                                         {/* <a href="/privacy" className="text-blue-500">Privacy Policy</a>, and <a href="/cookies" className="text-blue-500">Cookie Policy.</a>* */}
                                    </label>
                                    {errors.includes('You must agree to the Terms of Service and Privacy Policy.') && <p className="text-red-500 text-sm mt-1">You must agree to the Terms of Service and Privacy Policy.</p>}
                                </div>

                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="keep_updated"
                                        checked={keepUpdated}
                                        onChange={() => setKeepUpdated(!keepUpdated)}
                                    />
                                    <label className="text-gray-700">Keep me updated with news and offers.</label>
                                </div>

                                <button
                                    type="submit"
                                    className={`w-full bg-blue-500 text-white p-2 rounded-lg ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={loading}
                                >
                                    {loading ? 'Sending...' : 'Send'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
            <Footer />

    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Terms isOpen={isModalOpen} onClose={closeModal}>
        <Policies/>
      </Terms>
         </div>
        </>
    );
};

export default Contact;
