import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
// import { useDocTitle } from '../components/CustomHook';

const ThankYou = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
  };

  return (
    <>
      <NavBar />
      <main className="flex flex-col items-center justify-center min-h-screen bg-gray-50 py-12">
        <div className="w-full max-w-lg bg-white p-8 rounded-lg shadow-lg text-center">
          <h1 className="text-2xl font-bold text-blue-900 mb-6">Thank You!</h1>
          <p className="text-gray-600 mb-6">Thank you for reaching out to us. We will get back to you soon.</p>
          <p className="text-gray-600 mb-6">Your message has been received successfully.</p>
          <button 
            onClick={handleHomeClick} 
            className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Back to Home
          </button>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ThankYou;
