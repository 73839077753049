// TermsAndConditionsContent.js
import React from 'react';

const TermsAndConditionsContent = () => (
  <div>
    <h1 className='font-bold text-3xl'>Terms and Conditions</h1>
    <br/>
    <p>Welcome to Crawlsight! These Terms and Conditions outline the rules and regulations for the use of our website and services.</p>
   <b>1. Definitions :</b>
    CrawlSight refers to any person who uses the Site.
    <br/>
    <b>2. Account Responsibility:</b>
  You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
<br/>
<b>3. Intellectual Property Ownership :</b>
 All content and materials on the Site, including but not limited to text, graphics, logos, and software, are the property of Crawlsight or its licensors and are protected by copyright, trademark, and other intellectual property laws.
License: You are granted a limited, non-exclusive, non-transferable license to access and use the Site for personal, non-commercial purposes. You may not copy, reproduce, distribute, or create derivative works from any content on the Site without our prior written consent.
<br/>
<b>4. User Content Submission :</b>
By submitting content to the Site (e.g., comments, reviews, or other materials), you grant us a worldwide, royalty-free, perpetual, and irrevocable license to use, reproduce, modify, and distribute such content.
Responsibility: You are solely responsible for any content you submit and must ensure that it does not infringe on the rights of any third party.
<br/>
<b>6. Third-Party Links :</b>
The Site may contain links to third-party websites or services that are not owned or controlled by Crawlsight. We are not responsible for the content or practices of any third-party sites and recommend reviewing their terms and privacy policies.
<br/>
<b>7. Limitation of Liability :</b>
To the fullest extent permitted by law, Crawlsight shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Site. Our liability to you for any claim arising from these Terms and Conditions shall not exceed the amount you paid to us in the last six months preceding the claim.
<br/>
<b>8. Indemnification :</b>
You agree to indemnify, defend, and hold harmless Crawlsight, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses (including reasonable attorneys' fees) arising out of or related to your use of the Site or violation of these Terms and Conditions.
<br/>
<b>9. Termination :</b>
We reserve the right to terminate or suspend your access to the Site at our sole discretion, without notice, for any reason, including but not limited to your violation of these Terms and Conditions.
<br/>
<b>10. Changes to These Terms :</b>
We may modify these Terms and Conditions from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the Site after any changes constitutes your acceptance of the revised Terms and Conditions.
<br/>
<b>11. Governing Law :</b>
These Terms and Conditions shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.
<br/>

<b>12. Prohibited Uses: </b>
You may not use the Site for any illegal or unauthorized purpose. This includes but is not limited to transmitting harmful or unlawful content, engaging in fraudulent activities, or interfering with the Site’s functionality.
<br/>
<b>13. Contact Information :</b>
If you have any questions or concerns about these Terms and Conditions, please contact us at:
<br/>
    <p>For more information, please contact us at Crawlsight.com.</p>
  </div>
);

export default TermsAndConditionsContent;
