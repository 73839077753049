import React from 'react';
// import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/main-image.svg';

const Hero = () => {

    // const listStyle = {
    //     listStyleType: 'disc', // or 'circle', 'square', etc.
    //     paddingLeft: '20px',
    //   };
  return (
    <>

      <div className="hero" id="crawlsight">
        <div>
          <NavBar />
        </div>

        <br/>
        <br/>

        <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">
                    <div id='hero' className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="100">
                            <h1 className="text-2xl lg:text-3xl text-blue-900 font-bold mb-4">
                            {/* We build digital solutions to help businesses scale */}
                            At <span className='text-sky-500'>Crawlsight</span>, we provide reliable, high quality data from millions of web pages
                            </h1>
                            <br/>
                            <div className="text-2xl font-semibold tracking-tight mb-5 text-gray-500"> Our advanced web data extraction methods ensure that you receive complete and trustworthy data, perfectly tailored for your analytical and business needs, always on time.</div>

                            <div className="text-5xl font-semibold tracking-tight mb-5 text-gray-500">1,000+&nbsp; Data Catalogs<br/> 1B+ &nbsp;Pages Crawled</div>
                           
                        </div>
                        <div className="flex lg:justify-end w-full lg:w-1/2" data-aos="fade-up" data-aos-delay="300">
                            <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={heroImg} />
                        </div>
                    </div>
                </div>
        
      </div>
    </>
  );
};

export default Hero;
