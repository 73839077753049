import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './NavLinks.css'; // Ensure this CSS file is created or styles are added to your global stylesheet.

import api from "./Navbar-images/api.png"
import feedback from "./Navbar-images/FB.png"
import digital from "./Navbar-images/web3.png"
import finance from "./Navbar-images/investment.png"

const NavLinks = () => {
    const [openDropdown, setOpenDropdown] = useState(null);
    // const [isHovering, setIsHovering] = useState(false);
    // const [timeoutId, setTimeoutId] = useState(null);

    const handleMouseEnter = (dropdownName) => {
        setOpenDropdown(dropdownName);
    };

    const handleMouseLeave = () => {
        setOpenDropdown(null);
    };


    const refreshOnTop = (event)=> {

        if (event.target.innerText === 'Contact Us'){
            window.location.href = '/contact'
        }
        else if(event.target.innerText === 'Blogs'){
            window.location.href = '/use-cases'
        }
       
    }


    return (
        <nav className="flex items-center space-x-4">
            {/* Products Dropdown */}
            <div className="relative group drop" onMouseEnter={() => handleMouseEnter('products')} onMouseLeave={handleMouseLeave}>
                <button className="flex items-center px-4 text-sm text-gray-700 hover:text-sky-600">
                   <span className='navTitle'>Products</span> 
                    <svg
                        className={`w-4 h-4 ml-2 transform transition-transform ${openDropdown === 'products' ? 'rotate-180' : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 9.586l3.293-2.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>

                <div className={`absolute top-full left-1/2 transform -translate-x-1/2 ml-48 bg-white shadow-lg rounded-lg w-[900px] transition duration-100 ease-in-out 
                    ${
                        openDropdown === 'products' ? 'block' : 'hidden'
                    }`}
                >
                    <div className="flex p-6 pt-12">
                        {/* Data Scrapper Column */}
                        <div className="flex-1 mr-6">
                            {/* <h3 className="text-lg font-semibold text-gray-800 mb-4">Data Scrapper</h3> */}
                            <div className="space-y-2">
                                <HashLink
                                    className="flex items-center p-6 hover:bg-gray-100 rounded-lg transition"
                                    smooth
                                    to="/" // Updated path
                                >
                                    <div className="w-24 h-16  flex items-center justify-center rounded-full mr-4">
                                        {/* <svg className="w-10 h-10 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                            <path d="M12 2L2 7l10 5 10-5-10-5zm0 11L2 13l10 5 10-5-10-5v11z" />
                                        </svg> */}
                                       <img className='w-32 h-16' src={api} alt='card img'/>
                                    </div>
                                    <div>
                                    <span className="text-gray-800 jost">Web Data API</span>
                                    <br/>
                                    <ul className='list'>
                                        <li>Access data from thousands of websites with a scalable, cost-effective Web Data API.</li>
                                        {/* <li>Compatible with any programming language</li> */}
                                    </ul> 
                                    </div>

                                </HashLink>
                                <HashLink
                                    className="flex items-center p-6 hover:bg-gray-100 rounded-lg transition"
                                    smooth
                                    to=""
                                >
                                    <div className="w-28 h-16  flex items-center justify-center rounded-full mr-4">
                                    <img className='w-32 h-16' src={digital} alt='card img'/>
                                    </div>
                                    <div>
                                    <span className="text-gray-800 jost">Digital Web Presence</span>
                                    <br/>
                                    <ul className='list'>
                                        <li>Elevate your web presence with our review, feedback, and rating management services.</li>
                                        {/* <li>Standardized way to interact with data, </li> */}
                                    </ul> 
                                    </div>
                                </HashLink>
                            </div>
                        </div>
                        {/* LLM Column */}
                        <div className="flex-1 mr-6">
                            {/* <h3 className="text-lg font-semibold text-gray-800 mb-4">LLM</h3> */}
                            <div className="space-y-2">
                                <HashLink
                                    className="flex items-center p-6 hover:bg-gray-100 rounded-lg transition"
                                    smooth
                                    to=""
                                >
                                    <div className="w-30 h-16 flex items-center justify-center rounded-full mr-4">
                                        <img className='w-32 h-16' src={feedback} alt='card img'/>
                                    </div>

                                    <div>
                                    <span className="text-gray-800 jost">Digital Feedback Analytics</span>
                                    <br/>
                                    <ul className='list'>
                                        <li>Analyze digital feedback with advanced tools to gain insights and drive strategic decisions.</li>
                                        {/* <li>Standardized way to interact with data, </li> */}
                                    </ul> 
                                    </div>
                                </HashLink>
                                <HashLink
                                    className="flex items-center p-6 hover:bg-gray-100 rounded-lg transition"
                                    smooth
                                    to=""
                                >
                                    <div className="w-28 h-16 flex items-center justify-center rounded-full mr-4">
                                    <img className='w-32 h-16' src={finance} alt='card img'/>
                                    </div>
                                    <div>
                                    <span className="text-gray-800 jost">Financial News Analytics</span>
                                    <br/>
                                    <ul className='list'>
                                        <li>Get real-time financial news analytics, offering insights to support investment decisions and marketing strategies.</li>
                                        {/* <li>Standardized way to interact with data, </li> */}
                                    </ul> 
                                    </div>
                                </HashLink>
                            </div>
                        </div>
                        {/* Insights Column */}
                        {/* <div className="flex-1"> */}
                            {/* <h3 className="text-lg font-semibold text-gray-800 mb-4">Insights</h3> */}
                            {/* <div className="space-y-2">
                                <HashLink
                                    className="flex items-center p-3 hover:bg-gray-100 rounded-lg transition"
                                    smooth
                                    to="/di1"
                                >
                                    <div className="w-16 h-16 bg-gray-300 flex items-center justify-center rounded-full mr-4">
                                        <svg className="w-10 h-10 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                            <path d="M12 2L2 7l10 5 10-5-10-5zm0 11L2 13l10 5 10-5-10-5v11z" />
                                        </svg>
                                    </div>
                                    <span className="text-gray-800">DI 1</span>
                                </HashLink>
                                <HashLink
                                    className="flex items-center p-3 hover:bg-gray-100 rounded-lg transition"
                                    smooth
                                    to="/di2"
                                >
                                    <div className="w-16 h-16 bg-gray-300 flex items-center justify-center rounded-full mr-4">
                                        <svg className="w-10 h-10 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                            <path d="M12 2L2 7l10 5 10-5-10-5zm0 11L2 13l10 5 10-5-10-5v11z" />
                                        </svg>
                                    </div>
                                    <span className="text-gray-800">DI 2</span>
                                </HashLink>
                            </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>



            {/* Catalog part of next / find code in assets/catalog.js*/}

            {/* Catalogs Dropdown */}
        

            {/* Other Links */}
            <HashLink className="items-center px-4 subpixel-antialiased text-sm  text-gray-700 hover:text-sky-600" smooth to="/#services">
               <span className='navTitle'>Services</span> 
            </HashLink>

            <HashLink className="items-center px-4 subpixel-antialiased text-sm text-gray-700 hover:text-sky-600" to="/use-cases"
                    onClick={refreshOnTop}>
               <span className='navTitle'>Blogs</span> 
            </HashLink>
            <Link className="items-center px-4 subpixel-antialiased text-sm  text-gray-700 hover:text-sky-600" to="/contact" onClick={refreshOnTop}> 
               <span className='navTitle'>Contact Us</span> 
            </Link>
            {/* <HashLink className="items-center px-4 subpixel-antialiased text-sm text-gray-700 hover:text-sky-600" to="/signup">
               <span className='navTitle'>Signup</span> 
            </HashLink> */}

        </nav>
    );
};

export default NavLinks;
