import React from 'react';
import img from '../images/data_service.jpg'; 
import img2 from '../images/analytics.jpg';
import img3 from '../images/data_engineering.jpg';
import img4 from '../images/ml.jpg';

const Services = () => {
  return (
    <div id="services" className="bg-neutral-100 py-4">
      {/* Section: Service Overview */}
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            Services
          </h2>
          <div className='flex justify-center'>
            <div className='w-24 border-b-4 border-blue-900'></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
          Transforming data into actionable insights with cutting-edge web services, advanced analytics, robust engineering, and AI-driven solutions
          </h2>
        </div>

        <div className="px-4 sm:px-12" data-aos="fade-down" data-aos-delay="300">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {/* Service Card 1 */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-lg p-4 group">
              <img
                alt="Web Data Service"
                className="rounded-t group-hover:scale-105 transition-transform duration-300"
                src={img}
              />
              <h2 className="font-semibold my-4 text-xl text-center">
                Web Data Service
              </h2>
              <p className="text-sm font-medium">
                Simplify your data needs with Crawlsight's Data-as-a-Service
                subscription. Whether you're in real estate, finance, marketing,
                etc., we've got you covered. Say goodbye to the
                hassle of setting up complex data crawlers and worrying about
                website bans. We handle all the complexities, delivering
                accurate and complete data seamlessly, so you can focus on what
                matters most.
              </p>
            </div>

            {/* Service Card 2 */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-lg p-4 group">
              <img
                alt="Data Analytics"
                className="rounded-t group-hover:scale-105 transition-transform duration-300"
                src={img2}
              />
              <h2 className="font-semibold my-4 text-xl text-center">
                Data Analytics
              </h2>
              <p className="text-sm font-medium">
              Get a complete view of your data with our advanced analytics tools.
              Visualize data with interactive dashboards, tailored to your industry needs. 
              Convert insights into actions, all through a user-friendly interface. 
              Enjoy real-time access, scalable solutions, and top-tier data security. 
              Our service ensures you stay informed and make confident, data-driven decisions.
              </p>
            </div>

            {/* Service Card 3 */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-lg p-4 group">
              <img
                alt="Data Engineering"
                className="rounded-t group-hover:scale-105 transition-transform duration-300"
                src={img3}
              />
              <h2 className="font-semibold my-4 text-xl text-center">
                Data Engineering
              </h2>
              <p className="text-sm font-medium">
              Build robust data pipelines with our end-to-end data engineering solutions. 
              We handle data integration, transformation, and storage ensuring clean, reliable data for analysis.
              Our scalable architecture supports your growing needs, while our expert team manages the complexities, 
              so you can focus on leveraging your data for business success.
              </p>
            </div>

            {/* Service Card 4 */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-lg p-4 group">
              <img
                alt="Data Science & AI"
                className="rounded-t group-hover:scale-105 transition-transform duration-300"
                src={img4}
              />
              <h2 className="font-semibold my-4 text-xl text-center">
                Data Science & AI
              </h2>
              <p className="text-sm font-medium">
              Leverage intelligent insights with our Machine Learning, AI, and Large Language Model (LLM) solutions. We offer tailored models for predictive analytics, automation, and natural language processing. Our expert team ensures seamless integration, real-time results, and scalable solutions, empowering your business to innovate, optimize operations, and stay ahead in a competitive market.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Additional Information */}
      <section>
        <div className="m-auto max-w-6xl p-4 md:p-12">
          <h2 className="my-4 text-center text-3xl text-blue-900 uppercase font-bold">
            Why choose us ?
          </h2>
          <div className='flex justify-center'>
            <div className='w-24 border-b-4 border-blue-900'></div>
          </div>
          <div className="flex mt-2 flex-col-reverse lg:flex-row py-8 justify-between lg:text-center">
            {/* Scalability Section */}
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center mb-8 lg:mb-0">
              {/* <div className='text-blue-900 mb-4'>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className='fill-current'
                >
                  <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                  <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
                </svg>
              </div> */}
              <h3 className="text-3xl text-blue-900 font-bold">
                <span className='font-black'>Scalability</span>
              </h3>
              <p className='my-3 text-xl text-gray-600 font-semibold'>
              Whether you're a startup or a large enterprise, our flexible infrastructure scales effortlessly to meet your demands. We handle everything from small, focused data sets to large-scale, high-volume data extraction, ensuring that your growing needs are always met.
              </p>
            </div>

            {/* Accuracy Section */}
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              {/* <div className='text-blue-900 mb-4'>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className='fill-current'
                >
                  <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                </svg>
              </div> */}
              <h3 className="text-3xl text-blue-900 font-bold">
                <span className='font-black'>Accuracy</span>
              </h3>
              <p className='my-3 text-xl text-gray-600 font-semibold'>
              We understand that the value of data lies in its precision. Our advanced crawling algorithms and data cleaning processes ensure that you receive high-quality, accurate data, minimizing errors and providing reliable insights for your business decisions
              </p>
            </div>

              {/* Support Section */}
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              {/* <div className='text-blue-900 mb-4'>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className='fill-current'
                >
                  <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                </svg>
              </div> */}
              <h3 className="text-3xl text-blue-900 font-bold">
                <span className='font-black'> Support</span>
              </h3>
              <p className='my-3 text-xl text-gray-600 font-semibold'>
             Navigating data challenges can be complex, but you're not alone. Our team of experienced data professionals is here to offer expert guidance at every step. From initial consultation to ongoing support, we ensure that you maximize the value of the data we provide.
              </p>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
