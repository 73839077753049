import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import integrationImage1 from '../images/integration.jpg';
import integrationImage2 from '../images/coverage.jpg';
import integrationImage3 from '../images/comprehensive.png';
import integrationImage4 from '../images/effortless.png';

const Product1 = () => {
    useDocTitle('CrawlSight');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProductDetails = () => {
            setTimeout(() => {
                try {
                    setLoading(false);
                } catch (err) {
                    setError('Failed to fetch product details.');
                    setLoading(false);
                }
            }, 1000);
        };

        fetchProductDetails();
    }, []);

    return (
        <>
            <NavBar />
            <main className="flex flex-col items-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100 py-12 lg:py-24">
                <div className="container mx-auto px-4 lg:px-8">
                    {loading && <p className="text-center text-lg font-medium">Loading...</p>}
                    {error && <p className="text-center text-lg font-medium text-red-400">{error}</p>}
                    {!loading && !error && (
                        <div className="bg-gray-850 p-8 lg:p-12 rounded-3xl shadow-2xl max-w-5xl mx-auto">
                            <h1 className="text-4xl lg:text-5xl font-extrabold text-center mb-8 leading-tight text-gradient">
                                One Step Solution For Web Scraping
                            </h1>
                            <p className="text-lg lg:text-xl text-gray-300 mb-12 text-center">
                                Unlock the Power of Data with Our Advanced Web Scraper
                            </p>

                            <section className="grid gap-12 lg:grid-cols-2">
                                {[{
                                    img: integrationImage1,
                                    title: 'Seamless Integration',
                                    description: 'Our web scraper integrates effortlessly with your existing systems, ensuring that you can access and utilize data without disrupting your current workflow. The extracted data is delivered in a structured format, making it easy to analyze and use in your projects.'
                                }, {
                                    img: integrationImage2,
                                    title: 'Unmatched Reach',
                                    description: 'We are capable of scraping any site, regardless of its complexity. Our advanced algorithms adapt to different website structures, so you can be sure that no valuable data is left behind. From e-commerce platforms and social media sites to news outlets and blogs, our tool covers it all.'
                                }, {
                                    img: integrationImage3,
                                    title: 'Accuracy and Reliability',
                                    description: 'With our web scraper, you can trust that the data you receive is accurate and up-to-date. We prioritize data quality, and our technology ensures that you get reliable information every time.'
                                }, {
                                    img: integrationImage4,
                                    title: 'Effortless Setup',
                                    description: 'Getting started with our web scraping solution is quick and easy. Our user-friendly interface allows you to configure your scraping tasks with minimal effort, so you can focus on what matters most – analyzing and acting on the data.'
                                }].map((section, index) => (
                                    <div key={index} className="relative bg-gray-800 p-6 rounded-3xl shadow-lg transform transition-transform duration-500 hover:scale-105 hover:shadow-2xl animate-fade-in-up">
                                        <div className="relative flex flex-col lg:flex-row items-center lg:items-start space-y-6 lg:space-y-0 lg:space-x-6">
                                            <div className="w-full lg:w-1/2 overflow-hidden rounded-2xl">
                                                <img
                                                    src={section.img}
                                                    alt={section.title}
                                                    className="w-full h-full object-cover rounded-2xl"
                                                    style={{ height: 'auto' }}
                                                />
                                            </div>
                                            <div className="w-full lg:w-1/2">
                                                <h3 className="text-2xl lg:text-3xl font-bold text-gradient mb-4">
                                                    {section.title}
                                                </h3>
                                                <p className="text-gray-300">
                                                    {section.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </section>

                            <div className="text-center mt-12">
                                <p className="text-lg text-gray-300">
                                    Discover how our web scraping solution can revolutionize the way you gather and use information. Contact us today to learn more or schedule a demo!
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Product1;
