import React from 'react';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id="products">
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Products</h2>
                <div className='flex justify-center mb-6'>
                    <div className='w-24 border-b-4 border-blue-900'></div>
                </div>
                <div className="px-4" data-aos="fade-down" data-aos-delay="300">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">                            
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Web Data API</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-65">
                                Ready to transform your data experience ? Explore the possibilities with our Web Data API and take your applications to the next level. Unlock the full potential of your data with our cutting-edge Web Data API. Whether you're building a new application or enhancing an existing one, our API offers a robust, flexible, and secure solution for accessing and managing your web data.
                                </p>
                                {/* Removed Link component */}
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Digital Web Presence</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-65">
                                Ready to elevate your digital presence? Contact us today to discuss your needs and explore how our Digital Web Presence solutions can transform your online identity. Our Digital Web Presence solutions are designed to help you establish, enhance, and manage your online identity with precision and impact. Elevate Your Brand Online.
                                </p>
                                {/* Removed Link component */}
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Digital Feedback Analysis</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-65">
                                Ready to harness the power of customer feedback?, understanding what your customers think and feel is more crucial than ever. Our Digital Feedback Analysis services provide you with the tools and insights needed to decode customer feedback, make data-driven decisions, and drive meaningful improvements to your business.
                                </p>
                                {/* Removed Link component */}
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Financial News Analytics</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-65">
                                Ready to enhance your financial decision-making with cutting-edge news analytics? Navigate the Market with Confidence. In today’s fast-paced financial world, staying ahead of market trends and making informed investment decisions is crucial. Our Financial News Analytics services provide you with the tools and insights needed to decode financial news, understand market movements, and gain a competitive edge.
                                </p>
                                {/* Removed Link component */}
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </>
    );
}

export default Portfolio;
