// src/pages/UseCasesPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

// Import images
import priceEdgeImage from '../images/priceEdgeImage.svg'; 
import competitionMonitoringImage from '../images/competition.png'; 
import dataAnalyticsImage from '../images/clients/planning.png'; 
import marketResearchImage from '../images/analytics.jpg'; 

const UseCasesPage = () => {
    useDocTitle('CrawlSight | Use Cases');

    return (
        <>
            <NavBar />
            <main className="flex flex-col items-center min-h-screen bg-gray-50 text-gray-900 py-12 lg:py-24">
                <div className="container mx-auto px-4 lg:px-8">
                    <div className="bg-white p-6 lg:p-8 border border-gray-300 rounded-lg shadow-lg max-w-6xl mx-auto">
                        <h1 className="text-2xl lg:text-3xl font-bold text-gray-900 mb-8 text-center">
                            Our Use Cases
                        </h1>

                        {/* Grid view for use cases */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                            {/* Use Case Box - PriceEdge */}
                            <div className="bg-white border border-gray-200 p-6 flex flex-col h-full shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
                                <img
                                    src={priceEdgeImage}
                                    alt="PriceEdge"
                                    className="w-full h-48 object-cover mb-4"
                                />
                                <h2 className="text-lg lg:text-xl font-semibold text-gray-900 mb-1">
                                    Financial News Sentiment Analysis
                                </h2>
                                <div className="flex-grow flex flex-col justify-between">
                                    <p className="text-gray-600 mb-2"> </p>
                                    <p className="text-gray-600 text-xs mb-1 font-roboto">Published: Jun 10, 2024</p>
                                </div>
                                <Link
                                    to="/article-financial_news_analysis"
                                    className="block px-4 py-2 bg-blue-600 text-white font-semibold text-sm border border-blue-600 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out text-center mt-2"
                                >
                                    Learn More
                                </Link>
                            </div>

                            {/* Use Case Box - Competition Monitoring */}
                            <div className="bg-white border border-gray-200 p-6 flex flex-col h-full shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
                                <img
                                    src={competitionMonitoringImage}
                                    alt="Competition Monitoring"
                                    className="w-full h-48 object-cover mb-4"
                                />
                                <h2 className="text-lg lg:text-xl font-semibold text-gray-900 mb-1">
                                    Competition Analysis to Grow Your Business
                                </h2>
                                <div className="flex-grow flex flex-col justify-between">
                                    <p className="text-gray-600 mb-2"> </p>
                                    <p className="text-gray-600 text-xs mb-1 font-roboto">Published: July 7, 2024</p>
                                </div>
                                <Link
                                    to="/article-competition_monitoring"
                                    className="block px-4 py-2 bg-blue-600 text-white font-semibold text-sm border border-blue-600 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out text-center mt-2"
                                >
                                    Learn More
                                </Link>
                            </div>

                            {/* Use Case Box - Data Analytics */}
                            <div className="bg-white border border-gray-200 p-6 flex flex-col h-full shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
                                <img
                                    src={dataAnalyticsImage}
                                    alt="Data Analytics"
                                    className="w-full h-48 object-cover mb-4"
                                />
                                <h2 className="text-lg lg:text-xl font-semibold text-gray-900 mb-1">
                                    Create Historical Summary of an Entity Based on News Events and Triggers
                                </h2>
                                <div className="flex-grow flex flex-col justify-between">
                                    <p className="text-gray-600 mb-2"> </p>
                                    <p className="text-gray-600 text-xs mb-1 font-roboto">Published: Aug 15, 2024</p>
                                </div>
                                <Link
                                    to="/article-summary_of_an_entity_based_on_news_events"
                                    className="block px-4 py-2 bg-blue-600 text-white font-semibold text-sm border border-blue-600 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out text-center mt-2"
                                >
                                    Learn More
                                </Link>
                            </div>

                            {/* Use Case Box - Market Research */}
                            <div className="bg-white border border-gray-200 p-6 flex flex-col h-full shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
                                <img
                                    src={marketResearchImage}
                                    alt="Market Research"
                                    className="w-full h-48 object-cover mb-4"
                                />
                                <h2 className="text-lg lg:text-xl font-semibold text-gray-900 mb-1">
                                    Building Food Review Summarization Model Using CrawlSight Food Review Dataset
                                </h2>
                                <div className="flex-grow flex flex-col justify-between">
                                    <p className="text-gray-600 mb-2"> </p>
                                    <p className="text-gray-600 text-xs mb-1 font-roboto">Published: Sept 22, 2024</p>
                                </div>
                                <Link
                                    to="/article-food_review_summarization_model"
                                    className="block px-4 py-2 bg-blue-600 text-white font-semibold text-sm border border-blue-600 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out text-center mt-2"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default UseCasesPage;
