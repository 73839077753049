import React from 'react';
import news_report_img from '../images/clients/news-report.png';
import property_img from '../images/clients/property.png';
import planning_img from '../images/clients/planning.png';
import healthcare_img from '../images/clients/healthcare.png'; // Corrected from duplicate 'planning_img'
import automotive_img from '../images/clients/automotive.png';
import insurance_img from '../images/clients/insurance.png';
import online_shop_img from '../images/clients/online-shop.png';
import commodity_img from '../images/clients/commodity.png';
import dinner_img from '../images/clients/dinner.png';
import accounting_img from '../images/clients/accounting.png';

const clientImage = {
    height: '6rem', // Adjust the height for smaller images
    width: 'auto',
    mixBlendMode: 'colorBurn'
};

const imageCaptions = {
    Accounting: 'Finance',
    Automotive: 'Automotive',
    Commodity: 'Trade & Commodity',
    Hospitality: 'Hospitality',
    Healthcare: 'Healthcare',
    Insurance: 'Insurance',
    NewsReport: 'News And Media',
    Ecommerce: 'E-Commerce',
    Planning: 'Marketing',
    Property: 'Real Estate'
};

const Clients = () => {
    return (
        <div className="mt-8 bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Domain Expertise</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
                        Some of our most focused domain.
                    </h2>
                </div>

                <div className="p-16" data-aos="fade-in" data-aos-delay="500">
                    <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                        <figure className="flex flex-col items-center">
                            <img src={news_report_img} alt="Accounting" style={clientImage} className="object-cover" />
                            <figcaption className="mt-2 text-center font-semibold text-gray-700">{imageCaptions.NewsReport}</figcaption>
                        </figure>

                        <figure className="flex flex-col items-center">
                            <img src={property_img} alt="Automotive" style={clientImage} className="object-cover" />
                            <figcaption className="mt-2 text-center font-semibold text-gray-700">{imageCaptions.Property}</figcaption>
                        </figure>

                        <figure className="flex flex-col items-center">
                            <img src={planning_img} alt="Commodity" style={clientImage} className="object-cover" />
                            <figcaption className="mt-2 text-center font-semibold text-gray-700">{imageCaptions.Planning}</figcaption>
                        </figure>

                        <figure className="flex flex-col items-center">
                            <img src={healthcare_img} alt="Hospitality" style={clientImage} className="object-cover" />
                            <figcaption className="mt-2 text-center font-semibold text-gray-700">{imageCaptions.Healthcare}</figcaption>
                        </figure>

                        <figure className="flex flex-col items-center">
                            <img src={automotive_img} alt="Insurance" style={clientImage} className="object-cover" />
                            <figcaption className="mt-2 text-center font-semibold text-gray-700">{imageCaptions.Automotive}</figcaption>
                        </figure>

                        <figure className="flex flex-col items-center">
                            <img src={insurance_img} alt="News Report" style={clientImage} className="object-cover" />
                            <figcaption className="mt-2 text-center font-semibold text-gray-700">{imageCaptions.Insurance}</figcaption>
                        </figure>

                        <figure className="flex flex-col items-center">
                            <img src={online_shop_img} alt="Online Shop" style={clientImage} className="object-cover" />
                            <figcaption className="mt-2 text-center font-semibold text-gray-700">{imageCaptions.Ecommerce}</figcaption>
                        </figure>

                        <figure className="flex flex-col items-center">
                            <img src={commodity_img} alt="Planning" style={clientImage} className="object-cover" />
                            <figcaption className="mt-2 text-center font-semibold text-gray-700">{imageCaptions.Commodity}</figcaption>
                        </figure>

                        <figure className="flex flex-col items-center">
                            <img src={dinner_img} alt="Property" style={clientImage} className="object-cover" />
                            <figcaption className="mt-2 text-center font-semibold text-gray-700">{imageCaptions.Hospitality}</figcaption>
                        </figure>

                        <figure className="flex flex-col items-center">
                            <img src={accounting_img} alt="Healthcare" style={clientImage} className="object-cover" />
                            <figcaption className="mt-2 text-center font-semibold text-gray-700">{imageCaptions.Accounting}</figcaption>
                        </figure>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Clients;
