// src/pages/TermsAndConditions.js
import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';

const TermsAndConditions = () => {
    const lastUpdatedDate = new Date().toLocaleDateString(); // Dynamically get the last updated date

    return (
        <>
            <NavBar />
            <main className="flex items-center justify-center min-h-screen bg-gray-50 py-8">
                <div className="bg-white p-8 md:p-12 lg:p-16 rounded-lg shadow-lg border border-gray-200 max-w-4xl w-full">
                    <h1 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900 text-center">Terms and Conditions</h1>
                    <p className="text-gray-600 text-sm md:text-base mb-8 italic text-center">**Last Updated: {lastUpdatedDate}**</p>

                    <section className="mb-12 border-t border-gray-300 pt-8">
                        <h2 className="text-3xl font-semibold mb-4 text-gray-800">1. Use of the Site</h2>
                        <p className="mb-4"><strong>1.1 Eligibility</strong>: You must be at least 18 years old to use the Site. By using the Site, you represent and warrant that you are 18 years of age or older.</p>
                        <p className="mb-4"><strong>1.2 User Account</strong>: Some features of the Site may require you to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</p>
                        <p className="mb-4"><strong>1.3 Prohibited Conduct</strong>: You agree not to engage in any of the following prohibited activities:</p>
                        <ul className="list-disc ml-5 mb-4 text-gray-700 space-y-2">
                            <li>Using the Site for any unlawful purpose or in violation of any applicable law.</li>
                            <li>Attempting to interfere with the operation of the Site or any server or network used by the Site.</li>
                            <li>Transmitting any malware, viruses, or other harmful code.</li>
                            <li>Impersonating any person or entity or misrepresenting your affiliation with any person or entity.</li>
                        </ul>
                    </section>

                    <section className="mb-12 border-t border-gray-300 pt-8">
                        <h2 className="text-3xl font-semibold mb-4 text-gray-800">2. Intellectual Property</h2>
                        <p className="mb-4"><strong>2.1 Ownership</strong>: The Site and its contents, including but not limited to text, graphics, logos, and software, are the property of CrawlSight or its licensors and are protected by intellectual property laws.</p>
                        <p className="mb-4"><strong>2.2 License</strong>: We grant you a limited, non-exclusive, non-transferable license to access and use the Site for personal, non-commercial purposes. You may not reproduce, distribute, modify, or create derivative works based on the Site or its contents without our prior written consent.</p>
                    </section>

                    <section className="mb-12 border-t border-gray-300 pt-8">
                        <h2 className="text-3xl font-semibold mb-4 text-gray-800">3. Disclaimer of Warranties</h2>
                        <p className="mb-4"><strong>3.1 No Warranty</strong>: The Site is provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not warrant that the Site will be error-free or uninterrupted, or that any defects will be corrected.</p>
                    </section>

                    <section className="mb-12 border-t border-gray-300 pt-8">
                        <h2 className="text-3xl font-semibold mb-4 text-gray-800">4. Limitation of Liability</h2>
                        <p className="mb-4"><strong>4.1 Limitation</strong>: To the fullest extent permitted by law, [Your Company Name] shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Site, even if we have been advised of the possibility of such damages.</p>
                    </section>

                    <section className="mb-12 border-t border-gray-300 pt-8">
                        <h2 className="text-3xl font-semibold mb-4 text-gray-800">5. Changes to the Terms</h2>
                        <p className="mb-4"><strong>5.1 Modifications</strong>: We may update these Terms from time to time. We will notify you of any material changes by posting the updated Terms on the Site. Your continued use of the Site after any changes constitutes your acceptance of the revised Terms.</p>
                    </section>

                    <section className="mb-12 border-t border-gray-300 pt-8">
                        <h2 className="text-3xl font-semibold mb-4 text-gray-800">6. Governing Law</h2>
                        <p className="mb-4"><strong>6.1 Law</strong>: These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.</p>
                    </section>

                    <section>
                        <h2 className="text-3xl font-semibold mb-4 text-gray-800">7. Contact Us</h2>
                        <p className="mb-4"><strong>7.1 Contact Information</strong>: If you have any questions or concerns about these Terms, please contact us at crawlsight.com.</p>
                    </section>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default TermsAndConditions;
