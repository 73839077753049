import React from 'react';
import img from '../images/Intro2.png';
import { Link } from 'react-router-dom';

const Intro = () => {

    const refreshOnTop=()=>{
        window.location.href = '/contact'
    }
    return (
        <div className="min-h-screen flex flex-col justify-center bg-gray-100" id="about">
            <div className="flex flex-col lg:flex-row items-center justify-center py-8 px-4 lg:px-8">
                {/* Image Section */}
                <div className="lg:w-1/2 flex justify-center mb-8 lg:mb-0">
                    <img 
                        alt="Web Developer Illustration" 
                        className="rounded-lg max-w-lg h-auto" 
                        src={img} 
                    />
                </div>

                {/* Text Section */}
                <div className="lg:w-1/2 text-center lg:text-left">
                    <h3 className="text-2xl lg:text-3xl text-blue-900 font-bold mb-4">
                        
                    Get the data delivered on your favorite cloud platform 
                        
                    </h3>

                    {/* We deliver high-quality, customized web scraping solutions for organizations, institutions, and SMEs. Our services enable seamless data extraction and integration tailored to your unique needs. */}

                    <p className="my-3 text-2xl font-semibold tracking-tight mb-5 text-gray-500">
                        We support AWS, GCP, Azure integration. With a decade of experience, we meet your data needs to drive business success. 
                    </p>
                    <p className="my-3 text-2xl font-semibold tracking-tight mb-5 text-gray-500">
                    Our proprietary Data Trust Score technology powers our Data Quality process, ensuring you always receive high-quality data. Enjoy flexible pricing—no subscription required. Pay only for the data you consume.                 
                        </p>
                    <Link 
                        to="/contact"  onClick={refreshOnTop}
                        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
                    >
                        Contact Us
                        <svg 
                            className="w-4 h-4 ml-1 group-hover:translate-x-2" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 20 20" 
                            fill="currentColor"
                        >
                            <path 
                                fillRule="evenodd" 
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" 
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Intro;
